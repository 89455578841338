export const travel = [
	{
	name: "All NueGo Routes",
	route: "/all-routes"
	},
	{
		name: "Offers",
		route: "/offers"
	},
	{
		name: "Lounge",
		route: "/lounge"
	},
	{
		name: "Partner with Us",
		route: "/partner-with-us"
	},
]

export const more = [
	{
		name: "About Us",
		route: '/about-us'
	},
	{
		name: "Careers",
		route: "/careers"
	},
	{
		name: "Newsroom",
		route: "/news"
	},
	{
		name: "Environment",
		route: "/environment"
	},
	{
		name: "Magazine",
		route: "/magazine"
	},
	{
		name: "Blog",
		route: "/blog"
	}

]

export const help = [
	{
		name: "Support",
		route: "/call"
	},
	{
		name: "FAQs",
		route: "/faq"
	},
	{
		name: "Sitemap",
		route: "/sitemap"
	},
	// {
	// 	name:"Privacy Policy",
	// 	route:"/privacy-policy"
	// }

]