import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useSearchParams, Link } from "react-router-dom"
import MobileSubScreenLayout from "../../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import ReactMarkdown from "react-markdown";
import { Helmet } from 'react-helmet';
import cogoToast from "cogo-toast";
import Loader from "../../../components/Loader/Loader";
import { CustomToast } from "../../../components/CustomToast/CustomToast"
// icons
import AuthModalLayout from "../../../layout/AuthModalLayout/AuthModalLayout";
import "../Blog.scss"

function Best15PlacestoVisitInHaridwar() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();



    return (
        <div className={"specific-blog-Page"}>
            <Helmet>
                <title>Places to Visit in Haridwar: Top 15 Attractions | NueGo</title>
                <link rel="canonical" href="https://www.nuego.in/blog/sightseeing-places-to-visit-in-haridwar" hreflang="en-IN" />
                <meta name="description" content="Discover the best attractions in Haridwar. Plan your trip with NueGo bus services to visit the most popular places and enjoy top sightseeing spots in Haridwar."/>
                <meta name="keywords" content=""></meta>
                <meta name="robots" content="index,follow" />
            </Helmet>
            {

                isDesktopOrLaptop ? <AuthModalLayout>
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                </AuthModalLayout>
                    :
                    <MobileSubScreenLayout back={() => navigate(-1)} title={"NueGo Blog"}>
                        <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                    </MobileSubScreenLayout>
            }
        </div>
    );
};

export const MainContent = () => {
    //console.log("parameters.get",parameters.get("pnr"))
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const [loading, setLoading] = useState(false);



    const data=[
        {
         
        tittle:"15 Best Sightseeing Places to Visit in Haridwar",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Haridwar_15%20Best%20Sightseeing.jpg",
        imgAlt:"Best Places to Visit in India During August",
        desc:`
\n\n

\n\n
Ganga whispers grace,       
Temples hum in twilight's glow,     
Haridwar's calm soul        
Makes the music flow.       

Am I a visitor?     
Or am I a dweller?      
Why does it matter,     
In Haridwar, time moves slow.       

Haridwar is cool. Both literally and figuratively. But the pleasant weather and hilly bliss aside, Haridwar is a lot cooler for what it has - a plethora of charming pilgrimage sites awaiting its devotees every year. The places to visit in Haridwar are countless, and the temples and ashrams in Haridwar are all a spectacular kaleidoscope of devotion and grace, waiting to be experienced by a passionate tourist such as yourself. 
\n\n
## Haridwar - A Summary
\n\n
### Top Attractions in Haridwar
Har Ki Pauri, Mansa Devi Temple, Chandi Devi Temple, Shantikunj, Bharat Mata Mandir
### Most Visited Markets in Haridwar
Moti Bazaar, Bara Bazaar, Kankhal Market, Govindpuri Market, Jwalapur Market
### The Most Popular Thing About Haridwar
Ganga Aarti at Har Ki Pauri
### Best Time to Visit Haridwar
October to March
### Top Foods to Try in Haridwar
Aloo Puri, Kachori, Chole Bhature, Lassi, Samosa, Jalebi, Aloo Tikki, Malaiyo, Rabri, Chaat
\n\n
So, let us immerse ourselves in the tune of Haridwar as we list the 15 best Haridwar sightseeing places that almost always feature in travel itineraries of Haridwar:
\n\n
## 1. Har Ki Pauri
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/shutterstock_1148438246_Har%20Ki%20Pauri.jpg "Har Ki Pauri")
\n\n
Har Ki Pauri epitomizes the spiritual magnetism of Haridwar. If Haridwar were a play, Har Ki Pauri would be the grand climax. Lord Vishnu is said to have made his sacred footstep at this Ghat by the river Ganges. How could this not be a step of divinity? Among the places to visit in Haridwar, Har Ki Pauri is the most significant one connected to the ancient history of Haridwar.

Ganga Aarti takes place every evening here. You will see the priests with big lamps of fire, the flames jumping to the tune of the rhythmic chanting of hymns. Har Ki Pauri creates an atmosphere that is electric, and charged with faith and devotion. It's a place where you lose yourself in the crowd and find your spirit simultaneously. And if you are wondering, yes, it is pretty touristy, but isn't that part exactly what we’re looking for? A place to celebrate divinity together? Sharing such a profound moment with hundreds of others with everyone lost in the same reverence is an experience that can't be matched. Har Ki Pauri is among the best places to visit in Haridwar.
\n\n
**Location**: Haridwar      
**Timings**: Open 24 hours      
**Ganga Aarti Timings**: 5:30am - 6:30 am, 6 pm - 7 pm (May change depending on the season)     
\n\n
_[Book your Bus Tickets from Delhi to Haridwar](https://www.nuego.in/all-routes/bus/Delhi-to-Haridwar)_
\n\n
## 2. Mansa Devi Temple
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/shutterstock_2274765225_Mansa%20Devi%20Temple.jpg "Mansa Devi Temple")
\n\n
If there's one thing that Haridwar is not short of, it has to be temples. There are many famous places in Haridwar that are connected to devotion. The Mansa Devi Temple, however, stands out,  on top of the hill of Binwa Parvat. You can hike up the hill or take a cable car. This temple is dedicated to the goddess Mansa Devi, who is known for making her devotees' wishes come true.

Imagine this. You, standing in front of the deity, with a wish on your lips and a prayer in your heart. The faint breeze with a wisp of incense, the sound of bells in the background. This is the kind of stuff that makes one start believing in magic again in Haridwar. Also, don’t forget to tie a sacred thread to the branches of the holy tree outside the temple. You never know, maybe your wish will come true. Mansa Devi Temple is one of the top attractions in Haridwar. Log in to NueGo bus to find out about the best bus ticket offers to Haridwar.	
\n\n
**Location**: Bilwa Parvat, Haridwar        
**Distance from Har Ki Pauri**: Approximately 2.5 km        
**Timings**: 8:00 AM - 5:00 PM      
\n\n
## 3. Chandi Devi Temple
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/shutterstock_402122272_Chandi%20Devi%20Temple.jpg "Chandi Devi Temple")
\n\n
 
Haridwar likes its temples on hills, and the Chandi Devi Temple is yet another, perched high up, this time on the Neel Parvat. This temple is one of the famous places in Haridwar perched over a hill. Legend has it that Adi Shankaracharya built the temple in the 8th century. 

This is the temple dedicated to Goddess Chandi, who, according to the legend, killed the demon kings Shumbha and Nishumbha right here. This is a place drenched in myth and devotionalism. Standing beside it, with the view of the vast expanse of Haridwar below, you cannot but feel connected to something ancient and powerful. As a bonus, you will also witness the pleasant winds from the mountains.
\n\n
**Location**: Neel Parvat, Haridwar     
**Distance from Har Ki Pauri**: Approximately 4.5 km        
**Timings**: 7:00 AM - 7:00 PM      
\n\n
## 4. Shantikunj
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/15585450_1382998215051957_1245113344876029209_o_Shantikunj.jpg "Shantikunj")
\n\n
Shantikunj is an independent spiritual complex and not just another temple or ghat like most attractions in Haridwar. Pandit Shriram Sharma Acharya founded it and it is the headquarters of the All World Gayatri Pariwar. It's a place for the spiritual awakening of humanity and promoting Vedic learning through holistic living.

Walking around Shantikunj, you simply cannot overlook the sprawling beautiful lawns, meditation halls, and Vedic research centers. It is a very peaceful retreat from the hustle and bustle of life. Here, you can join the yoga and meditation sessions or any spiritual workshop or just sit in quiet contemplation. This is the best place to recharge your spiritual batteries. Shantikunj is one of the most interesting and educating Haridwar tourist attractions.
\n\n
**Location: Haridwar** - Dehradun Road      
**Distance from Har Ki Pauri**: Approximately 7 km      
**Timings**: 5:00 AM - 10:00 PM     
\n\n
_[Book AC Bus Tickets from Delhi to Haridwar](https://www.nuego.in/all-routes/bus/Delhi-to-Haridwar)_
\n\n
## 5. Bharat Mata Mandir
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/Bharat-Mata-Temple-Haridwar.jpg "Bharat Mata Mandir")
\n\n
Bharat Mata Mandir is a temple dedicated not to a deity but to Mother India. It is certainly one of the top attractions in Haridwar. Inaugurated by Indira Gandhi, this eight-story temple has been raised in reverence for the Indian way of life, its culture and history saliently, and its heroes. Each temple floor is dedicated to a different aspect of Indian heritage.

As you walk through the Temple, you will find elaborate dioramas and statues that depict scenes from India's rich history. There are statues of national leaders, freedom fighters, and revered saints. It is indeed an enchanting journey into the past, albeit with a rather special presentation as to what makes India, well, India. What can we say, apart from the fact that, one can get a temple dedicated to an entire country. Bharat Mata Mandir is definitely one of the best places to visit in Haridwar.
\n\n
**Location**: Sapt Sarovar Road, Haridwar       
**Distance from Har Ki Pauri**: Approximately 5 km      
**Timings**: 5:00 AM - 9:00 PM      
\n\n
## 6. Sapt Rishi Ashram
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/saptrishi-ashram-haridwar-india-tourism-history.jpg "Sapt Rishi Ashram")
\n\n
Haridwar is the residence of another pleasing aspect: ashrams. Sapt Rishi Ashram is one of the most famous, named after the seven great sages (Sapt Rishis) who meditated in this green land. Legend has it that the Ganges split into seven streams so the sages would not be disturbed in their meditation.

Today, Sapt Rishi Ashram serves as a peaceful retreat for those looking for spiritual solace. Activities there range from yoga and meditation to Vedic studies. One almost feels the presence of the ancient sages while walking the serene grounds of this ashram. It is one of those places where you can confine yourself entirely from all the chaos of the outside world and get back to your authentic self again. Among the Haridwar places to see, very little can match the serene calmness this place emanates.
\n\n
**Location**: Sapt Sarovar Road, Haridwar       
**Distance from Har Ki Pauri**: Approximately 7 km      
**Timings**: 5:00 AM - 9:00 PM      
\n\n
## 7. Rajaji National Park
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/Rajaji-National-Park.jpg "Rajaji National Park")
\n\n
Sprawling right next to Haridwar, the Rajaji National Park is one of those wildlife reserves known throughout India. Rich in wildlife, this place hosts elephants, tigers, leopards, and more than 400 species of birds.

Jeep safaris are available for exploring the park, and they take one deep into the forest to view some of the park's elusive inhabitants. From dense forests to open grasslands, with the Ganges River flowing in between, this forms a varied landscape. It is a wild, untamed side of Haridwar that looks absolutely beautiful and contrasts so well with the city's spiritual sanctuaries. This park is definitely one of the best places to visit in Haridwar if you are a nature lover.
\n\n
**Location**: Haridwar      
**Distance from Har Ki Pauri**: Approximately 10 km     
**Timings**: 6:00 AM - 9:00 AM and 3:00 PM - 6:00 PM (Closed during monsoon)        
\n\n
## 8. Patanjali Yogpeeth
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/about-patanjali.jpg "Patanjali Yogpeeth")
\n\n
If you are a person who loves  Yoga and Ayurveda, then Patanjali Yogpeeth is the place to be. Founded by the much-famed yoga guru Baba Ramdev, this institute is centered around the cause of popularizing yoga and Ayurveda. It is one of the commercial institutions of yoga, one of the most prominent ones in India, featuring a wide range of programs for health and wellbeing.

Once inside Patanjali Yogpeeth, you see sprawling green lawns with state-of-the-art yoga halls and well-stocked Ayurvedic pharmacies. It's the place where, let's say, modern amenities are at loggerheads with tradition. You would feel a sense of calmness and rejuvenation jetting inside as you would either be attending a yoga session getting an Ayurvedic treatment within the premises, or just walking around. Patanjali Yogpeeth is a rare gem among the famous Haridwar places.

**Location**: Haridwar - Roorkee Road, Haridwar     
**Distance from Har Ki Pauri**: Approximately 15 km     
**Timings**: 8:00 AM - 8:00 PM      
\n\n
_[Book Electric AC Bus Tickets from Delhi to Haridwar](https://www.nuego.in/all-routes/bus/Delhi-to-Haridwar)_
\n\n
## 9. Daksheswara Mahadev Temple
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/137201800Haridwar_Daksha_Mahadev_Temple_Main.jpg "Daksheswara Mahadev Temple")
\n\n
The Daksheswara Mahadev Prajapati Temple, also known as Daksha Temple, is located in the town of Kankhal, some kilometers off Haridwar.  It is a site steeped in mythology and history. This is believed to be the location where the yagna performed by King Daksha, father of Sati, happened.

Compensating for it, the serenity and beauty of the temple complex and intricate carvings make the environment very peaceful and silent. One could almost hear the voices of some long-lost legend murmuring in the air as one walked through the passage. During Maha Shivaratri, the temple is alive with a crowd of devotees who come to offer their prayers, which are said to be highly fulfilling for one's wishes, a magical juxtaposition of faith and devotion. It is one of the most popular attractions in Haridwar.
\n\n
**Location**: Kankhal, Haridwar     
**Distance from Har Ki Pauri**: Approximately 4 km      
**Timings**: 6:00 AM - 8:00 PM      
\n\n
## 10. Vaishno Devi
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/shutterstock_1174801381_Vaishno%20Devi.jpg "Vaishno Devi")
\n\n
You should know by now, that there is definitely more than one Vaishno Devi temple. Besides the eponymous temple in Katra, Jammu and Kashmir, there is another similar Vaishno Devi Temple located at Haridwar. 

The complex is designed to look like the original Vaishno Devi cave temple, with related tunnels and pathways that mimic the trek to the shrine. It is a wonderful experience for the people who could not make it to the original. Coupled with this, one gets some truly stunning views of Haridwar. Like the one in Jammu and Kashmir, the Vaishno Devi Temple in Haridwar is one of the most important Haridwar tourist attractions.
\n\n
**Location**: Jagdish Nagar, Haridwar       
**Distance from Har Ki Pauri**: Approximately 4 km      
**Timings**: 5:00 AM - 9:00 PM      
\n\n
## 11. Bhimgoda Kund
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/Haridwar_Bhimgoda_Tank_1620895641766_Bhimgoda%20Kund.jpg "Bhimgoda Kund")
\n\n
Bhimgoda Kund is also a historical and religious spot near Har Ki Pauri. It is said that this kund formed when Bhima, one of the Pandava brothers during the Mahabharata, hit his knee against the ground. The water of the Bhimgoda Kund is said to cure several illnesses and, therefore, attracts many devotees to it, who bathe there for health and religious purposes.

The calmness of Bhimgoda Kund's surroundings is further accentuated because there is ample greenery surrounding this place, with an atmosphere of deep tranquility. This is a place where one could be seated for some time in deep thought or reflection, reminiscing those ancient tales, which form an integral part of the charm of Haridwar. Not far from here is another beautiful spot, the Bhimgoda Barrage, which looks out onto the River Ganges and around. Most tourists visit this beautiful pond very often among the Haridwar sightseeing places.

**Location**: Near Har Ki Pauri, Haridwar       
**Distance from Har Ki Pauri**: Approximately 1 km      
**Timings**: 6:00 AM - 8:00 PM      
\n\n
_[Book AC Bus Tickets from Delhi to Haridwar](https://www.nuego.in/all-routes/bus/Delhi-to-Haridwar)_
\n\n
## 12. Pawan Dham
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/a399ebe282bd502822e5c615dfe3f05d_1000x1000_Pawan%20Dham.jpg "Pawan Dham")
\n\n
Perched along the Rishikesh Road, this Pawan Dham temple is renowned for its complex glasswork and unique architecture. The colored glass cuttings, which add decoration to the walls and ceilings of the temple, give a kaleidoscopic effect and leave one spellbound. 
The stars of the temple idols are no less spectacular, vibrantly built in marble engravings and dramatically colored. 
Pawan Dham is a place where art meets spirituality. As you walk through this charming establishment, it feels like you have stepped into an altogether different world. This space is apt for some reflective meditation or pondering its construction. Pawan Dham is one of the best Haridwar tour places to visit.	
\n\n
**Location**: Bhagirathi Nagar, Haridwar        
**Distance from Har Ki Pauri**: Approximately 3 km      
**Timings**: 6:00 AM - 8:00 PM      
\n\n
## 13. Sureshwari Devi Temple
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/sureshwari-devi-temple.jpg "Sureshwari Devi Temple")
\n\n
This Sureshwari Devi Temple lies within the complex of the Rajaji National Park. Devotees seek the blessings of goddess Sureshwari at this temple. This place is also a delight for people who are nature lovers. Moreover, the way to the temple demarcates much of the adventure activity area as one goes through the expanse of dense forests of the national park. Among the Haridwar tour places, this temple is known for having the most vegetation and greenery surrounding it.	

It's a serene, peaceful environment filled with the sounds of nature. The perfect place to escape into tranquility far removed from the din and bustle of the city, one will stand here at the temple to admire the serenity of the surroundings and inspire the feeling of attachment to nature and the divine.
\n\n
**Location**: Rajaji National Park, Haridwar        
**Distance from Har Ki Pauri**: Approximately 16 km     
**Timings**: 6:00 AM - 8:00 PM      
\n\n
## 14. Parad Shivling
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/137201530Haridwar_Parad_Shivling_Main_Parad%20Shivling.jpg "Parad Shivling")
\n\n
Another unique attraction in Haridwar is the Parad Shivling inside Harihar Ashram at Kankhal. This Shivling is made out of 151 kilograms of pure mercury, which is a very rare sight. It is said to have unique spiritual and medicinal properties attached to it. The surrounding area is a beautiful garden that renders the place peaceful for contemplation and prayer.

The ashram also welcomes various other deities and is a center for learning spiritualism and meditation. Devotees flock to it, resting in meditation, seeking blessings, and understanding the spiritual value of mercury in Hindu rituals. The Parad Shivling speaks as a testimonial to all the rich culture and heritage that Haridwar is so popular for. Among the places to visit in Haridwar, the Parad Shivling is a rare place for sure. 

**Location**: Harihar Ashram, Kankhal, Haridwar     
**Distance from Har Ki Pauri**: Approximately 4 km      
**Timings**: 6:00 AM - 8:00 PM      
\n\n
## 15. Maya Devi Temple
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15-Best-Sightseeing-Places-to-Visit-in-Haridwar/shutterstock_2329684721.jpg "Maya Devi Temple")
\n\n
Maya Devi Temple is one of the three Siddha Peethas in Haridwar, the other two being Chandi Devi and Mansa Devi temples. This ancient temple is dedicated to Goddess Maya, who, in turn, is said to be one of the several forms of Adhisthatri in Haridwar. This is one of the three places where the heart and navel of Goddess Sati fell when her body was being dismembered.

The solace the temple gives out has a spiritual feel and is visited by devotees and tourists. It is constructed beautifully with minimalistic architectural work that gives it an elegant look, surrounded by lovely idols of the goddess. A visit to the Maya Devi temple will ensure peace and deep interconnection with the Divine Feminine. 
\n\n
**Location**: Upper Road, Haridwar      
**Distance from Har Ki Pauri**: Approximately 2 km      
**Timings**: 6:00 AM - 8:00 PM      
\n\n
So, if you like what you hear, make it a point to not miss out on the best places in Haridwar when you pick up your travel gear. You won’t be disappointed with this pleasant and dreamy experience. So _[book your tickets now](https://www.nuego.in/all-routes/bus/Delhi-to-Haridwar)_ with NueGo Electric AC Buses!
`       
        }
        ]

    

    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-6000w-20s-28h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }



    return (
        <>
            {isDesktopOrLaptop && <Navbar />}
            {loading ? <Loader /> :
                <div className={"main-content"}>
                    {isDesktopOrLaptop && <div className="bread-crumb d-flex">
                        <script type="application/ld+json">
                            {JSON.stringify(
                            {
                            "@context": "https://schema.org/", 
                            "@type": "BreadcrumbList", 
                            "itemListElement": [{
                                "@type": "ListItem", 
                                "position": 1, 
                                "name": "Home",
                                "item": "https://www.nuego.in/"
                            
                            },{
                                "@type": "ListItem", 
                                "position": 2, 
                                "name": "blog",
                                "item": "https://www.nuego.in/blog/"
                            
                            },{
                                "@type": "ListItem", 
                                "position": 3, 
                                "name": "sightseeing places to visit in haridwar",
                                "item": "https://www.nuego.in/blog/sightseeing-places-to-visit-in-haridwar"
                            
                            }]
                            })}
                        </script>
                        <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <Link to="/blog"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0 '}>Blog</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>best-south-indian-tourist-places-to-visit</p>
                    </div>
                    }
                    <div className="container d-flex flex-column">
                        {
                            data.map((item, indx)=>{
                                return <div key={indx} class="blog-card">
                                  <br />
                                    <h1>{item.tittle}</h1>
                                    <br />
                                    <img src={item.img} alt={item.imgAlt} />
                                    <br />
                                    <div className="blog-content">
                                    <ReactMarkdown>{item.desc}</ReactMarkdown>
                                    </div>
                            </div>
                            })
                        }                       
                    </div>
                </div>
            }
            <Footer />
        </>
    );
}

export default Best15PlacestoVisitInHaridwar