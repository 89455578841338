import React ,{useState} from 'react'
import "./BlogCategory.scss"
import { data }from "../data"
import { Link } from 'react-router-dom'

function CategoryBlogs() {
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [showAll, setShowAll] = useState(false);
    

    const filteredData = selectedCategory === 'All'
    ? data
    : data.filter(item => item.category === selectedCategory);

    const itemsToDisplay = showAll ? filteredData : filteredData.slice(0, 3);

  return (
    <div className='blog-page-category-section'>
              <div className='head-subhead'>
            <div className='head'>
                <img className='icon' src="https://cdn.nuego.co.in/greencell/assets/images/Discount.png" alt="" />
                <h2 className='heading'>Blogs: Travel Insights and Inspiration</h2>
            </div>
            <h3 className='subhead'>Join us as we uncover hidden gems and explore breathtaking destinations worldwide.</h3>
      </div>
    {/* Category Tabs */}
    <div className="category-tabs">
      {['All', 'Adventure', 'Religious', 'Heritage', 'Hidden Gems', 'Weekend Trips'].map(item => (
        <button 
          key={item} 
          onClick={() => setSelectedCategory(item)}
          className={selectedCategory === item ? 'active' : ''}
        >
          {item}
        </button>
      ))}
    </div>

    {/* Render the filtered blog posts */}
    <div className="grid-container">
      {itemsToDisplay.map((item, index) => (
        <div key={index} className={`grid-item  ${index === 0 ? 'item1' : index === 1 ? 'item2' : 'item3'}`}>
          <img src={item.img} alt={item.imgAlt} />
          <div className='mweb'>
            <h3>{item.tittle}</h3>
            <p>{item.desc}</p>
            <Link to={item.route}>Read More &gt;</Link>
          </div>    
        </div>
      ))}
    </div>
    {filteredData?.length>3 && <button className='view-all-cta' onClick={()=>setShowAll(!showAll)}>{showAll?'View Less':'View All'}</button>}
  </div>
  )
}

export default CategoryBlogs