import graphql from 'babel-plugin-relay/macro';

const GetMyProfileQuery = graphql`
    query GetMyProfileQuery($userId: String) {
        getMyProfile(userId: $userId) {
            id
            walletAmount
            greenMilesAmount
            user {
                mobileNumber
                username
                firstName
                lastName
                email
                isActive
                savedcontactdetail {
                    user {
                        email
                        mobileNumber
                        firstName
                        lastName

                    }
                }
            }
            profilePic
            age
            gender
            registeredName
            gstin
            gstState
            profilePicUrl
            savedEmissions
        }
    }

`;

export default GetMyProfileQuery;