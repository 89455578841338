const OfferModalFrame = process.env.REACT_APP_CDN_LINK + "assets/images/offerModalframe.png";
const cardLogoTwo = process.env.REACT_APP_CDN_LINK + "assets/images/frameLogoTwo.png";
const card3 = process.env.REACT_APP_CDN_LINK + "assets/images/card3.png";

export const offerModalData = [
// 	{
//     id: 0,
//     frameImage: OfferModalFrame,
//     discountDetails: "Get upto ₹200 cashback on MobiKwik Zip",
//     superOffer: cardLogoOne,
//     toDate: " Expires on 30-Jun",
//     offterDatas: [{offerData: "No coupon code required to avail the offer"}, {offerData: "Offer is valid from 18th April 2023 to 30-Jun 2023"}, {offerData: "Offer is applicable for a minimum transaction value of INR 400. Minimum assured cashback of INR 40."}, {offerData: "User must select MobiKwik Zip option on the Payment Gateway to complete the transaction"}, {offerData: "Offer is valid only once per month per user during the Campaign"}, {offerData: "Cashback will be credited to customer’s MobiKwik wallet within 24 hours"},]
// },
	{
		id: 0,
		frameImage: OfferModalFrame,
		discountDetails: "Get upto ₹100 cashback on MobiKwik Wallet",
		superOffer: cardLogoTwo,
		toDate: " Expires on 30-Sep",
		offterDatas: [{offerData: "Get up to INR 100 cashback on bookings done with NueGo"}, {offerData: "Offer is valid from 1st July 2023 - 30th Sep 2023"}, {offerData: "Offer is applicable for a minimum transaction value of INR 500."}, {offerData: "Customers must select the MobiKwik option on the Payment Gateway and enter the coupon code MBK100 there"}, {offerData: "Offer is valid only once per month per user during the Campaign"}, {offerData: "Cashback will be credited to customer’s MobiKwik wallet within 24 hours"},]
	}]

export const offerStaticData = [
// 	{
//     id: 1,
//     applyFor: "0",
//     discountDetailsOne: "Get upto ",
//     discountDetailsTwo: "₹200",
//     discountDetailsThree: " cashback on MobiKwik Zip ",
//     discountId: "2872",
//     cardImage: card2, // discountSchemeName: "new user",
//     discountType: "1",
//     fromDate: "31-03-2023", // offerCode: "Newuser",
//     partnerId: "0",
//     toDate: " 30-Jun",
//     superOffer: cardLogoOne,
//     alt: "₹200 cashback offer on NueGo bus ticket with MobiKwik Zip for cheap tickets",
// },
	{
		id: 1,
		applyFor: "0",
		discountDetailsOne: " Get upto ",
		discountDetailsTwo: " ₹100 ",
		discountDetailsThree: "cashback on MobiKwik Wallet",
		discountId: "2872",
		cardImage: card3, // discountSchemeName: "new user",
		discountType: "1",
		fromDate: "1-07-2023",
		offerCode: "CODE: MBK100",
		partnerId: "0",
		toDate: " 30-Sep",
		superOffer: cardLogoTwo,
		alt: "₹100 cashback offer on NueGo bus ticket with MobiKwik Wallet for cheapest fare",
	}]